import { ExportTimesheetTypes } from "../../enums/export-timesheet-types.enum";
import { ExportSummaryADP, ExportSummaryADPAPS, ExportSummaryMaritech, ExportSummaryPaycom, ExportSummaryRedHook } from "../../interfaces/export-summary.interface";
import { ExportTimesheetFilters } from "../../interfaces/export-timesheet-filters.interface";

export class ExportTimesheetStateModel {
	filters: ExportTimesheetFilters;
	exportTimesheetType: ExportTimesheetTypes;
	summary: ExportSummary;
	loading: boolean;
}

export type ExportSummary = ExportSummaryADP[] | ExportSummaryADPAPS [] | ExportSummaryMaritech | ExportSummaryPaycom[] | ExportSummaryRedHook[];
