import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { ExportTimesheetTypes } from "../../enums/export-timesheet-types.enum";
import { ExportTimesheetFilters } from "../../interfaces/export-timesheet-filters.interface";
import { ExportTimesheetService } from "../../services/export-timesheet.service";
import { LoadSummary, ResetExportTimesheetState, SetExportType, UpdateFiltersToExport } from "./export-timesheet.actions";
import { ExportTimesheetStateModel } from "./export-timesheet.model";

@Injectable()
@State<ExportTimesheetStateModel>({
	name: "exportTimesheet",
	defaults: {
		filters: null,
		exportTimesheetType: null,
		summary: null,
		loading: false
	}
})
export class ExportTimesheetState {

	constructor(
		private exportTimesheetService: ExportTimesheetService
	) {}

	@Selector() static loading(state: ExportTimesheetStateModel) { return state.loading; }
	@Selector() static filters(state: ExportTimesheetStateModel) { return state.filters; }
	@Selector() static summary(state: ExportTimesheetStateModel) { return state.summary; }
	@Selector() static exportType(state: ExportTimesheetStateModel) { return state.exportTimesheetType; }

	@Action(UpdateFiltersToExport)
	updateFilters(ctx: StateContext<ExportTimesheetStateModel>, action: UpdateFiltersToExport) {
		ctx.patchState({
			filters: {
				payrollPeriod: action.payrollPeriod,
				year: action.year,
				isReExport: action.isReExport,
				submit: action.submit
			}
		});
	}

	@Action(SetExportType)
	setExportType(ctx: StateContext<ExportTimesheetStateModel>, action: SetExportType) {
		ctx.patchState({
			exportTimesheetType: action.exportType
		});
	}

	@Action(LoadSummary)
	loadSummary(ctx: StateContext<ExportTimesheetStateModel>) {
		ctx.patchState({
			loading: true
		});

		const state = ctx.getState();
		const httpRequest = this.getHttpRequestByExportType(state.exportTimesheetType, state.filters);
		return httpRequest.pipe(
			tap(
				(response) => {
					if (response) {
						ctx.patchState({
							summary: response,
							loading: false
						})
					}
				}
			)
		);
	}

	private getHttpRequestByExportType(exportTimesheetType: ExportTimesheetTypes, filters: ExportTimesheetFilters) {
		switch (exportTimesheetType) {
			case ExportTimesheetTypes.MaritechCsc:
			case ExportTimesheetTypes.MaritechRoro:
			default:
				return this.exportTimesheetService.getExportSummaryMaritech(filters);

			case ExportTimesheetTypes.AdpApmt:
				return this.exportTimesheetService.getExportSummaryADP(filters);

			case ExportTimesheetTypes.AdpAps:
				return this.exportTimesheetService.getExportSummaryADPAPS(filters);

			case ExportTimesheetTypes.Paycom:
				return this.exportTimesheetService.getExportSummaryPaycom(filters);

			case ExportTimesheetTypes.PaycomRoro:
				return this.exportTimesheetService.getExportSummaryPaycomRoro(filters);

      case ExportTimesheetTypes.RedHook:
				return this.exportTimesheetService.getExportSummaryRedHook(filters);
		}
	}

	@Action(ResetExportTimesheetState)
	resetExportTimesheetState(ctx: StateContext<ExportTimesheetStateModel>) {
		ctx.patchState({
			filters: null,
			exportTimesheetType: null,
			summary: null,
			loading: false
		});
	};
}
