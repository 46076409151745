import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { API_URL } from 'src/environments/settings';
import { ExportTimesheetTypes } from '../enums/export-timesheet-types.enum';
import { ExportTimesheetFilters } from '../interfaces/export-timesheet-filters.interface';

@Injectable({ providedIn: 'root' })
export class ExportTimesheetService {

    private apiUrl: string;

    constructor(
        private http: HttpClient,
        private env: EnvironmentService
    ) {
        this.apiUrl = this.env.getValueTransferState(API_URL);
    }

    getExportCSV(filters: ExportTimesheetFilters, exportType: ExportTimesheetTypes) {
        const headers = new HttpHeaders().set("Content-Type", "blob");
        const params: HttpParams = new HttpParams({
            fromObject: { ...filters }
        });
        const paths: string[] = [null, 'adpExportCsv', 'adpApsExportCsv', 'exportCsv', 'roroExportCsv', 'paycomExportCsv', 'paycomRoroExportCsv', 'redHookExportCsv'];
        return this.http.get(`${this.apiUrl}/app/timeSheet/${paths[exportType]}`, { params, headers, responseType: "blob" });
    }

    getExportSummaryMaritech(filters?: ExportTimesheetFilters) {
        const params: HttpParams = new HttpParams({
            fromObject: { ...filters }
        });
        return this.http.get<any>(`${this.apiUrl}/app/timeSheet/exportSummary`, { params });
    }

    getExportSummaryADP(filters?: ExportTimesheetFilters) {
        const params: HttpParams = new HttpParams({
            fromObject: { ...filters }
        });
        return this.http.get<any>(`${this.apiUrl}/app/timeSheet/adpExportSummary`, { params });
    }

    getExportSummaryADPAPS(filters?: ExportTimesheetFilters) {
        const params: HttpParams = new HttpParams({
            fromObject: { ...filters }
        });
        return this.http.get<any>(`${this.apiUrl}/app/timeSheet/adpApsExportSummary`, { params });
    }

    getExportSummaryPaycom(filters?: ExportTimesheetFilters) {
        const params: HttpParams = new HttpParams({
            fromObject: { ...filters }
        });
        return this.http.get<any>(`${this.apiUrl}/app/timeSheet/paycomExportSummary`, { params });
    }

    getExportSummaryPaycomRoro(filters?: ExportTimesheetFilters) {
        const params: HttpParams = new HttpParams({
            fromObject: { ...filters }
        });
        return this.http.get<any>(`${this.apiUrl}/app/timeSheet/paycomRoroExportSummary`, { params });
    }

    getExportSummaryRedHook(filters?: ExportTimesheetFilters) {
      const params: HttpParams = new HttpParams({
          fromObject: { ...filters }
      });
      return this.http.get<any>(`${this.apiUrl}/app/timeSheet/redHookExportSummary`, { params });
  }
}
